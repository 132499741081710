<template>
  <div class="mt-1 mb-10">
    <Header></Header>
    <div>
      <!--        <OneNFT class="ml-8 mt-3 float-left" v-for="item in"></OneNFT>-->
      <OneNFT :name="item['name']" :img-data="item['image']" :token-id="item.tokenId" class="ml-8 mt-3 float-left" v-for="item in myselfNFT"></OneNFT>
    </div>
    <div class="clear-both"></div>
  </div>
</template>

<script>
import OneNFT from "../NFTDisplay/oneNFT";
import Header from "./header";
import {mapState} from "vuex";
export default {
  name: "myselfBody",
  components:{
    OneNFT,
    Header
  },
  computed:{
    ...mapState(["connected","mymoney","userAddress","inInit","myselfNFT","connectTextContract","myselfNFTTokenIds"])
  },
  async mounted() {
    await this.initMySelf()
  },
  methods:{
    parseData(data){
      const json = Buffer.from(data.substring(29), "base64").toString()
      console.log(json)
      return JSON.parse(json)
    },
    async initMySelf(){
      if(this.myselfNFT.length<this.mymoney){
        let that=this
        this.$store.state.inInit=true
        let tokenList=await this.connectTextContract.getTokenList(this.userAddress)
        tokenList=tokenList[0].map(world=>world.toNumber())
        let tokenListMustGet=tokenList.filter(world=>that.myselfNFTTokenIds.indexOf(world)===-1)
        console.log("tokenListMustGet",tokenListMustGet)
        // 如果globalNFTs的长度是0，那么就不停的加载globalNFT
        for(let i of tokenListMustGet){
          console.log(i)
          let tokenURI=await this.connectTextContract.tokenURI(i)
          let result=this.parseData(tokenURI)
          console.log(result)
          result["tokenId"]=i
          // let poemFrom=(await this.connectTextContract.poemFrom(i)).toNumber()
          // result["poemFrom"]=poemFrom
          console.log(result)
          this.$store.state.myselfNFT.push(result)
          this.$store.state.myselfNFTTokenIds.push(i)
        }
        this.$store.state.inInit=false
      }
    }
  }
}
</script>

<style scoped>

</style>