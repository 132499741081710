<template>
<div class="mx-auto flex flex-col items-center my-10">
  <el-avatar :size="120" src="https://empty" @error="errorHandler">
    <img src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.zcool.cn%2Fcommunity%2F01ef465544623a0000019ae9487b9b.jpg&refer=http%3A%2F%2Fimg.zcool.cn&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1653557087&t=47d01ab880f762c3efc97a0b65bf3f77">
  </el-avatar>
  <div class="font-bold text-6xs my-1">UnNamed</div>
  <div class="flex items-center">
    <div>
      <svg fill="gray" viewBox="-38.39985 -104.22675 332.7987 625.3605" style="width: 12px;"><path d="M125.166 285.168l2.795 2.79 127.962-75.638L127.961 0l-2.795 9.5z"></path><path d="M127.962 287.959V0L0 212.32z"></path><path d="M126.386 412.306l1.575 4.6L256 236.587l-128.038 75.6-1.575 1.92z"></path><path d="M0 236.585l127.962 180.32v-104.72z"></path><path d="M127.961 154.159v133.799l127.96-75.637z"></path><path d="M127.96 154.159L0 212.32l127.96 75.637z"></path></svg>
    </div>
    <span class="ml-2 align-middle text-sm text-blue-600">{{cutUserAddress}}</span>
  </div>
</div>
</template>

<script>
import {mapState} from "vuex";
export default {
  name: "header",
  computed:{
    ...mapState(["connected","userAddress"]),
    cutUserAddress(){
      if(this.userAddress!=null){
        // "0xd0aB895321219ef1BfebA38929bf9e97c5E78Ae7"
        return this.userAddress.slice(0,7)+"..."+this.userAddress.slice(-4,42)
      }else{
        return "0x0000...0000"
      }
    }
  },
  watch:{

  },
  methods:{
    errorHandler(){

    }
  }
}
</script>

<style scoped>

</style>