<template>
  <div>
    <MyselfBody v-if="connected"></MyselfBody>
    <connect-wallet v-else></connect-wallet>
  </div>

</template>

<script>
import Header from "../components/myself/header"
import OneNFT from "../components/NFTDisplay/oneNFT";
import MyselfBody from "../components/myself/myselfBody";
import ConnectWallet from "../components/myself/connectWallet";
import {mapState} from "vuex";

export default {
  name: "me",
  computed:{
    ...mapState(["connected","mymoney","userAddress","inInit","myselfNFT","connectTextContract","myselfNFTTokenIds"])
  },
  components:{
    ConnectWallet,
    MyselfBody
  },
  methods:{
    parseData(data){
      const json = Buffer.from(data.substring(29), "base64").toString()
      console.log(json)
      return JSON.parse(json)
    },
    // async initMySelf(){
    //   if(this.myselfNFT.length<this.mymoney && this.inInit===false){
    //
    //     let that=this
    //     this.$store.state.inInit=true
    //     let tokenList=await this.connectTextContract.getTokenList(this.userAddress)
    //     tokenList=tokenList[0].map(world=>world.toNumber())
    //     let tokenListMustGet=tokenList.filter(world=>that.myselfNFTTokenIds.indexOf(world)===-1)
    //     console.log("tokenListMustGet",tokenListMustGet)
    //     // 如果globalNFTs的长度是0，那么就不停的加载globalNFT
    //     for(let i of tokenListMustGet){
    //       console.log(i)
    //       let tokenURI=await this.connectTextContract.tokenURI(i)
    //       let result=this.parseData(tokenURI)
    //       console.log(result)
    //       result["tokenId"]=i
    //       // let poemFrom=(await this.connectTextContract.poemFrom(i)).toNumber()
    //       // result["poemFrom"]=poemFrom
    //       console.log(result)
    //       this.$store.state.myselfNFT.push(result)
    //       this.$store.state.myselfNFTTokenIds.push(i)
    //     }
    //     this.$store.state.inInit=false
    //   }
    // }
  },
  // async mounted() {
  //   // if(this.mymoney===-1){
  //   //   this.$store.state.mymoney=await this.connectTextContract.balanceOf(this.userAddress)
  //   // }
  //   // let tokenList=await this.connectTextContract(this.userAddress)
  //   // console.log(tokenList)
  //   // console.log(this.myselfNFT,this.mymoney)
  //   await this.initMySelf()
  // }
}
</script>

<style scoped>

</style>